import React, { useEffect, useState } from 'react'
import Utopia_Logo from "../../Images/Utopia_Logo.png";
import largeevent from "../../Images/largeevent.jpg";
import AOS from 'aos';
import "aos/dist/aos.css"
const Intro = () => {
    useEffect(() => {
        AOS.init({
            duration: 600,
            delay: 700,
        });
    })
    const [paginationblock, setPaginationblock] = useState(1);

   
    return (
        <>
            <section className={paginationblock ? "block current block--hero-unit slide-in  " : "block block--hero-unit"}>
                <img className="block__background block__background--single responsive-picture" src={largeevent} />

                <div className='bg-pattern_cuurent'></div>

              

                <div className="block__content blockcontentdiscover"  >
                <p className='textforresponsiverrrx'>01 Intro</p>
                    {/* <img src={Utopia_Logo} alt="/" className="block__logo hp-logo" /> */}
                    <div className=''>
                        {/* <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp" >Welcome to Hot Events – Where Creators and Fans Meet</h1>
                            <h2 className="blcktitleparatxtp">
                                Hot Events is a dynamic platform designed to bridge the gap between content creators and their audiences through a seamless and engaging ticket-selling service. Our platform offers an integrated suite of features that encompasses social media interactions, direct ticket sales, comprehensive e-commerce tools, secure e-payment options, and robust advertising capabilities. Whether you're an artist, educator, entertainer, or innovator, Hot Events empowers you to connect with your audience in real-time, enriching the experience with every interaction.
                            </h2>
                            
                        </div> */}
                        {/* <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp">Engage, Connect, and Grow Your Audience</h1>
                            <h2 className="blcktitleparatxtp">
                                At Hot Events, we understand the importance of engagement. That's why we've created a user-friendly interface that not only facilitates the easy purchase and sale of event tickets but also enhances interaction through tailored social media features. Content creators can announce upcoming events, share updates, and engage with fans on a deeper level. Fans can follow their favorite creators, stay updated with real-time notifications, and access exclusive content, all in one place.
                            </h2>
                        </div>
                        <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp">Streamlined E-Commerce and Secure Transactions</h1>
                            <h2 className="blcktitleparatxtp">
                                Our platform is equipped with advanced e-commerce solutions designed to handle everything from ticket sales to merchandising. With robust analytics tools, creators can track sales patterns, audience preferences, and campaign effectiveness, allowing for optimized marketing strategies. Security is paramount at Hot Events, and our state-of-the-art e-payment system ensures that every transaction is secure, giving both creators and fans peace of mind.
                            </h2>
                        </div>
                        <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp">Maximize Visibility with Tailored Advertising</h1>
                            <h2 className="blcktitleparatxtp">
                                Hot Events provides powerful advertising tools that enable creators to reach wider audiences effectively. Our targeted advertising options ensure that your events and merchandise are seen by those most likely to be interested, maximizing impact and engagement. Whether you’re looking to promote a local workshop or a virtual concert, our platform can cater to your needs, ensuring that your events get the spotlight they deserve.
                            </h2>
                        </div> */}


                        <div class="accordion" id="accordionExample" data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="500">
                            <div class="accordion-item" >
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Welcome to Hot Events – Where Creators and Fans Meet
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        Hot Events is a dynamic platform designed to bridge the gap between content creators and their audiences through a seamless and engaging ticket-selling service. Our platform offers an integrated suite of features that encompasses social media interactions, direct ticket sales, comprehensive e-commerce tools, secure e-payment options, and robust advertising capabilities. Whether you're an artist, educator, entertainer, or innovator, Hot Events empowers you to connect with your audience in real-time, enriching the experience with every interaction.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Engage, Connect, and Grow Your Audience
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        At Hot Events, we understand the importance of engagement. That's why we've created a user-friendly interface that not only facilitates the easy purchase and sale of event tickets but also enhances interaction through tailored social media features. Content creators can announce upcoming events, share updates, and engage with fans on a deeper level. Fans can follow their favorite creators, stay updated with real-time notifications, and access exclusive content, all in one place.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Streamlined E-Commerce and Secure Transactions
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        Our platform is equipped with advanced e-commerce solutions designed to handle everything from ticket sales to merchandising. With robust analytics tools, creators can track sales patterns, audience preferences, and campaign effectiveness, allowing for optimized marketing strategies. Security is paramount at Hot Events, and our state-of-the-art e-payment system ensures that every transaction is secure, giving both creators and fans peace of mind.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" >
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Maximize Visibility with Tailored Advertising
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        Hot Events provides powerful advertising tools that enable creators to reach wider audiences effectively. Our targeted advertising options ensure that your events and merchandise are seen by those most likely to be interested, maximizing impact and engagement. Whether you’re looking to promote a local workshop or a virtual concert, our platform can cater to your needs, ensuring that your events get the spotlight they deserve.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item" >
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Your Venue, Your Stage
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                    Additionally, Hot Events offers a unique venue posting feature, allowing event organizers to showcase their venues, complete with detailed descriptions, seating charts, and real-time availability. This feature not only helps creators choose the perfect venue for their events but also assists fans in planning their attendance, providing all the information they need at their fingertips.
                                    <br>
                                    </br>
                                    Join Hot Events today and transform the way you connect with your audience. Experience a platform that supports your creative journey and brings your community together, one event at a time.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



                <div className="scroll-invitation">
                    <span className="scroll-invitation__label scroll-invitation__label--mobile">
                        Scroll down
                    </span>
                    <span className="scroll-invitation__label scroll-invitation__label--desktop">
                        <svg className="scroll-invitation__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 42" width="24px" height="42px">
                            <path d="M2,12H2V30a10,10,0,0,0,20,0V12A10,10,0,0,0,2,12ZM0,12a12,12,0,0,1,24,0V30A12,12,0,0,1,0,30V12Z" />
                            <path className="scroll-invitation__svg-line" d="M11,7v4a1,1,0,0,0,2,0V7a1,1,0,0,0-2,0Z" />
                        </svg>
                        Scroll Down
                    </span>
                </div>


            </section>
        </>
    )
}

export default Intro
