import React, { useEffect, useState } from 'react'
import Utopia_Logo from "../../Images/Utopia_Logo.png";
import eventtickets from "../../Images/eventtickets.jpg";
import AOS from 'aos';
import "aos/dist/aos.css"
const Connected = () => {
    useEffect(() => {
        AOS.init({
            duration: 600,
            delay: 700,
        });
    })
    const [paginationblock, setPaginationblock] = useState(1);
    return (
        <>
            <section className={paginationblock ? "block current block--hero-unit slide-in  " : "block block--hero-unit"}>
                <img className="block__background block__background--single responsive-picture" src={eventtickets} />

                <div className='bg-pattern_cuurent'></div>

                <div className="block__content blockcontentdiscover" >
                <p className='textforresponsiverrrx'>05 ConnectD</p>
                    <div className='blockcontentdivflx'>
                        {/* <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp" >Seamless Social Media Integration at Hot Events</h1>
                            <h2 className="blcktitleparatxtp">
                                At Hot Events, we understand the importance of social media in today's digital age. That's why our platform offers seamless integration with all major social media networks, allowing users to connect their Facebook, Twitter, Instagram, and more. This integration enables creators to amplify their reach by simultaneously sharing updates, event announcements, and content across multiple channels with just a few clicks. For attendees, it means staying informed and engaged with their favorite creators’ activities without ever missing a beat.
                            </h2>
                        </div>
                        <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp">Enhanced Engagement Through Unified Social Media Tools</h1>
                            <h2 className="blcktitleparatxtp">
                                Our platform not only facilitates the sharing of content across social networks but also enhances engagement by aggregating social media interactions. Users can view comments, likes, and shares from all connected platforms in a single, unified dashboard. This holistic view not only saves time but also provides valuable insights into audience engagement, helping creators tailor their content and interactions more effectively. For advertisers and partners, this means targeted campaigns that resonate with audiences, driven by real-time data and analytics.
                            </h2>
                        </div>
                        <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp">Building Community and Fostering Connections</h1>
                            <h2 className="blcktitleparatxtp">
                                Hot Events is more than just a ticket-selling and e-commerce platform; it's a community hub where fans can connect with creators and each other. By integrating social media, we make it easy for users to share their experiences, post reviews, and engage in discussions around events and products. This community-driven approach not only enriches the user experience but also fosters a sense of belonging and loyalty among fans, enhancing the overall vibrancy of the platform.
                            </h2>
                        </div> */}

                        <div class="accordion" id="accordionExample" data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="500">
                            <div class="accordion-item" >
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Seamless Social Media Integration at Hot Events
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        At Hot Events, we understand the importance of social media in today's digital age. That's why our platform offers seamless integration with all major social media networks, allowing users to connect their Facebook, Twitter, Instagram, and more. This integration enables creators to amplify their reach by simultaneously sharing updates, event announcements, and content across multiple channels with just a few clicks. For attendees, it means staying informed and engaged with their favorite creators’ activities without ever missing a beat.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Enhanced Engagement Through Unified Social Media Tools
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        Our platform not only facilitates the sharing of content across social networks but also enhances engagement by aggregating social media interactions. Users can view comments, likes, and shares from all connected platforms in a single, unified dashboard. This holistic view not only saves time but also provides valuable insights into audience engagement, helping creators tailor their content and interactions more effectively. For advertisers and partners, this means targeted campaigns that resonate with audiences, driven by real-time data and analytics.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Building Community and Fostering Connections
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        Hot Events is more than just a ticket-selling and e-commerce platform; it's a community hub where fans can connect with creators and each other. By integrating social media, we make it easy for users to share their experiences, post reviews, and engage in discussions around events and products. This community-driven approach not only enriches the user experience but also fosters a sense of belonging and loyalty among fans, enhancing the overall vibrancy of the platform.
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>



                <div className="scroll-invitation">
                    <span className="scroll-invitation__label scroll-invitation__label--mobile">
                        Scroll down
                    </span>
                    <span className="scroll-invitation__label scroll-invitation__label--desktop">
                        <svg className="scroll-invitation__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 42" width="24px" height="42px">
                            <path d="M2,12H2V30a10,10,0,0,0,20,0V12A10,10,0,0,0,2,12ZM0,12a12,12,0,0,1,24,0V30A12,12,0,0,1,0,30V12Z" />
                            <path className="scroll-invitation__svg-line" d="M11,7v4a1,1,0,0,0,2,0V7a1,1,0,0,0-2,0Z" />
                        </svg>
                        Scroll Down
                    </span>
                </div>


            </section>
        </>
    )
}

export default Connected
