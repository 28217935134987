import React, { useEffect, useState } from 'react'
import car from "../../Images/car.jpg";
import AOS from 'aos';
import Utopia_Logo from "../../Images/Utopia_Logo.png";
import advertising from "../../Images/advertising.jpg";
import "aos/dist/aos.css"
const HotCommerce = () => {
    useEffect(() => {
        AOS.init({
            duration: 600,
            delay: 700,
        });
    })
    const [paginationblock, setPaginationblock] = useState(1);
    return (
        <>
            <section className={paginationblock ? "block current block--hero-unit slide-in  " : "block block--hero-unit"}>
                <img className="block__background block__background--single responsive-picture" src={advertising} />

                <div className='bg-pattern_cuurent'></div>

                <div className="block__content blockcontentdiscover" >
                <p className='textforresponsiverrrx'>03 HOT Commerce</p>
                    <div className='blockcontentdivflx'>
                        {/* <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp" >Empowering Creators with a Robust HOTommerce Marketplace</h1>
                            <h2 className="blcktitleparatxtp">
                            Hot Events extends beyond ticket sales, offering content creators a comprehensive e-commerce marketplace designed to showcase and sell their products directly to fans. Whether you're a musician selling branded merchandise, an artist offering prints, or a speaker promoting books and courses, our platform provides the tools you need to create an attractive and effective online store. Each creator's storefront is customizable, allowing for a unique branding experience that resonates with their specific audience, enhancing brand recognition and loyalty.
                            </h2>
                        </div>
                        <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp">Streamlined Sales and Management Process</h1>
                            <h2 className="blcktitleparatxtp">
                            Managing an online store can be complex, but Hot Events makes it simple. Our platform integrates inventory management, real-time sales tracking, and customer analytics, all accessible through a user-friendly dashboard. Creators can easily add new products, set up promotions, and manage orders with automated shipping and tax calculations. This seamless integration ensures that creators can focus on what they do best—creating—while we handle the intricacies of e-commerce logistics.
                            </h2>
                        </div>
                        <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp">Enhancing Creator Revenue with Advanced Marketing Tools</h1>
                            <h2 className="blcktitleparatxtp">
                            To maximize revenue, Hot Events equips creators with advanced marketing tools that help promote their merchandise effectively. From targeted advertising campaigns to promotional discounts and exclusive deals for event ticket holders, our platform offers numerous ways to engage potential buyers and boost sales. Additionally, we provide analytics and reporting features that allow creators to understand customer preferences and behaviors, enabling them to tailor their offerings and marketing strategies to meet the evolving demands of their audience.
                            </h2>
                        </div> */}
                        <div class="accordion" id="accordionExample" data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="500">
                            <div class="accordion-item" >
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Empowering Creators with a Robust HOTommerce Marketplace
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                    Hot Events extends beyond ticket sales, offering content creators a comprehensive e-commerce marketplace designed to showcase and sell their products directly to fans. Whether you're a musician selling branded merchandise, an artist offering prints, or a speaker promoting books and courses, our platform provides the tools you need to create an attractive and effective online store. Each creator's storefront is customizable, allowing for a unique branding experience that resonates with their specific audience, enhancing brand recognition and loyalty.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Streamlined Sales and Management Process
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                    Managing an online store can be complex, but Hot Events makes it simple. Our platform integrates inventory management, real-time sales tracking, and customer analytics, all accessible through a user-friendly dashboard. Creators can easily add new products, set up promotions, and manage orders with automated shipping and tax calculations. This seamless integration ensures that creators can focus on what they do best—creating—while we handle the intricacies of e-commerce logistics.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Enhancing Creator Revenue with Advanced Marketing Tools
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                    To maximize revenue, Hot Events equips creators with advanced marketing tools that help promote their merchandise effectively. From targeted advertising campaigns to promotional discounts and exclusive deals for event ticket holders, our platform offers numerous ways to engage potential buyers and boost sales. Additionally, we provide analytics and reporting features that allow creators to understand customer preferences and behaviors, enabling them to tailor their offerings and marketing strategies to meet the evolving demands of their audience.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                <div className="scroll-invitation">
                    <span className="scroll-invitation__label scroll-invitation__label--mobile">
                        Scroll down
                    </span>
                    <span className="scroll-invitation__label scroll-invitation__label--desktop">
                        <svg className="scroll-invitation__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 42" width="24px" height="42px">
                            <path d="M2,12H2V30a10,10,0,0,0,20,0V12A10,10,0,0,0,2,12ZM0,12a12,12,0,0,1,24,0V30A12,12,0,0,1,0,30V12Z" />
                            <path className="scroll-invitation__svg-line" d="M11,7v4a1,1,0,0,0,2,0V7a1,1,0,0,0-2,0Z" />
                        </svg>
                        Scroll Down
                    </span>
                </div>


            </section>
        </>
    )
}

export default HotCommerce
