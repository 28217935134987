import React, { useEffect, useState } from 'react'
import Utopia_Logo from "../../Images/Utopia_Logo.png";
import car from "../../Images/car.jpg";
import AOS from 'aos';
import "aos/dist/aos.css"
import globalpaymentnew from "../../Images/globalpaymentnew.jpeg";

const GlobalPayment = () => {
    useEffect(() => {
        AOS.init({
            duration: 600,
            delay: 700,
        });
    })
    const [paginationblock, setPaginationblock] = useState(1);
    return (
        <>
            <section className={paginationblock ? "block current block--hero-unit slide-in  " : "block block--hero-unit"}>
                <img className="block__background block__background--single responsive-picture" src={globalpaymentnew} />

                <div className='bg-pattern_cuurent'></div>

                <div className="block__content blockcontentdiscover" >
                <p className='textforresponsiverrrx'>06 Global Payment</p>
                    <div className='blockcontentdivflx'>
                        {/* <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp" >Global Payment Acceptance and Distribution</h1>
                            <h2 className="blcktitleparatxtp">
                                At Hot Events, we empower our users to reach a global audience by accepting payments from anywhere in the world. Our platform is equipped with a sophisticated payment system that supports multiple currencies and payment methods, ensuring that fans and buyers can easily purchase tickets, and merchandise, or engage with sponsored content, regardless of their location. For creators and advertisers, this means tapping into an international market without the hassle of currency or payment barriers. Our system not only collects payments but also handles the complexities of global distribution, ensuring that funds are securely and promptly transferred to the respective parties.
                            </h2>
                        </div>
                        <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp">Robust Digital Wallets for All Users</h1>
                            <h2 className="blcktitleparatxtp">
                                Every user on Hot Events is provided with a digital wallet that offers a flexible and secure way to manage their funds. These wallets are designed to support a wide range of transactions, including adding and withdrawing money, receiving payments from ticket sales, e-commerce, and advertising revenue. Users can easily track their earnings and spending through a clear and intuitive interface, giving them full control over their finances. This functionality enhances user experience and fosters trust and reliability, encouraging more active participation on the platform.
                            </h2>
                        </div>
                        <div className='blockcontentdivcntn' data-aos="zoom-in" data-aos-duration="800">
                            <h1 className="blcktitleheadtxtp">Seamless Financial Management and Revenue Generation</h1>
                            <h2 className="blcktitleparatxtp">
                                The digital wallets on Hot Events facilitate seamless financial management, making it straightforward for users to capitalize on their creative output. Whether it's a creator selling out an event, a merchant moving a new line of products, or an influencer generating advertising revenue, our platform ensures that the financial rewards of their efforts are easily accessible. Moreover, our robust security measures protect all transactions, giving users peace of mind when handling their funds.
                            </h2>
                        </div> */}

                        <div class="accordion" id="accordionExample" data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="500">
                            <div class="accordion-item" >
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Global Payment Acceptance and Distribution
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        At Hot Events, we empower our users to reach a global audience by accepting payments from anywhere in the world. Our platform is equipped with a sophisticated payment system that supports multiple currencies and payment methods, ensuring that fans and buyers can easily purchase tickets, and merchandise, or engage with sponsored content, regardless of their location. For creators and advertisers, this means tapping into an international market without the hassle of currency or payment barriers. Our system not only collects payments but also handles the complexities of global distribution, ensuring that funds are securely and promptly transferred to the respective parties.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Robust Digital Wallets for All Users
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                    Every user on Hot Events is provided with a digital wallet that offers a flexible and secure way to manage their funds. These wallets are designed to support a wide range of transactions, including adding and withdrawing money, receiving payments from ticket sales, e-commerce, and advertising revenue. Users can easily track their earnings and spending through a clear and intuitive interface, giving them full control over their finances. This functionality enhances user experience and fosters trust and reliability, encouraging more active participation on the platform.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Seamless Financial Management and Revenue Generation
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                    The digital wallets on Hot Events facilitate seamless financial management, making it straightforward for users to capitalize on their creative output. Whether it's a creator selling out an event, a merchant moving a new line of products, or an influencer generating advertising revenue, our platform ensures that the financial rewards of their efforts are easily accessible. Moreover, our robust security measures protect all transactions, giving users peace of mind when handling their funds.
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>



                <div className="scroll-invitation">
                    <span className="scroll-invitation__label scroll-invitation__label--mobile">
                        Scroll down
                    </span>
                    <span className="scroll-invitation__label scroll-invitation__label--desktop">
                        <svg className="scroll-invitation__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 42" width="24px" height="42px">
                            <path d="M2,12H2V30a10,10,0,0,0,20,0V12A10,10,0,0,0,2,12ZM0,12a12,12,0,0,1,24,0V30A12,12,0,0,1,0,30V12Z" />
                            <path className="scroll-invitation__svg-line" d="M11,7v4a1,1,0,0,0,2,0V7a1,1,0,0,0-2,0Z" />
                        </svg>
                        Scroll Down
                    </span>
                </div>


            </section>
        </>
    )
}

export default GlobalPayment
