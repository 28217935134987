import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import headerlogo from "../../src/Images/header-logo.png";
import menulogo from "../../src/Images/menu-logo.png"
const Header = () => {
    const [sidebardivopen,setSidebardivopen]=useState(false)
    const sidebaropenclick =()=>{
        setSidebardivopen(!sidebardivopen );
    }
    return (
        <>
            <section className='headermain' >
                {/* <Link className="header__logo " to="/">
                  <img src={headerlogo} alt='...' />
                </Link> */}
                {/* <div className="hambargericon" >
                    <div className='hambargericonlinediv' onClick={sidebaropenclick}>
                        <div className="line1"></div>
                    </div>
                </div> */}

            </section>
            {/* <section className={sidebardivopen ? 'sidebardiv actv':'sidebardiv'} >
                <div className="sub-menu-back-button">Back</div>
                <nav className="menu__main">
                    <ul className="menu__list">
                        <li className="menu__link current">
                            <Link to="/" className='lnkmntetstxpp'>
                                Home
                            </Link>
                        </li>
                        <li className="menu__link">
                            <Link to="/" className='lnkmntetstxpp'>History</Link>
                        </li>
                        <li className="menu__link ">
                            <Link to="/" className='lnkmntetstxpp' >Hypercars</Link>
                        </li>
                        <li className="menu__link">
                            <Link to="/" className='lnkmntetstxpp' >Dealers</Link>
                        </li>
                        <li className="menu__link">
                            <Link to="/" className='lnkmntetstxpp' >Arte in Pista</Link>
                        </li>
                        <li className="menu__link ">
                            <Link to="/" className='lnkmntetstxpp' >Pagani Officina</Link>

                        </li>
                        <li className="menu__link">
                            <Link to="/" className='lnkmntetstxpp'>Visit Us</Link>

                        </li>
                        <li className="menu__link">
                            <Link to="/" className='lnkmntetstxpp'>Contact</Link>
                        </li>

                        <li className="menu__link">
                            <Link to="/" className='lnkmntetstxpp' >
                                Job &amp; Stage Opportunities
                            </Link>
                        </li>

                        <li className="menu__link">
                            <Link to="/" className='lnkmntetstxpp' >Press Room</Link>
                        </li>

                        <li className="menu__link ">
                            <Link to="/" className='lnkmntetstxpp' >Calendario Pagani</Link>

                        </li>

                        <li className="menu__link">
                            <Link to="/" className='lnkmntetstxpp' >Pagani Store</Link>
                        </li>

                        <li className="menu__link ">
                            <Link to="/" className='lnkmntetstxpp'>Legal &amp; Compliance</Link>

                        </li>
                    </ul>
                </nav>
                <div className='menulogosidebavrimg'>
                    <img src={menulogo} alt='...' />
                </div>
                <div className="menu__languages">
                    <ul className="menu__listdivftr">
                        <li className="menu__linklistcls">
                            <Link to="/" className='menu__linkvshttxtpp'>Italiano</Link>
                        </li>
                        <li className="menu__linklistcls">
                            <Link to="/" className='menu__linkvshttxtpp'>English</Link>
                        </li>
                    </ul>
                </div>
            </section> */}
        </>
    )
}

export default Header
