import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './Layout/MainLayout';
import Index from './Views/Home/Index';
import Discover from './Views/Discover/Discover';
import Contact from './Views/Contact/Contact';
import Privacypolicy from './Views/PrivacyPolicy/Privacypolicy';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          
            <Route  element={<MainLayout />}>
              <Route path="/" element={<Index />} />
              <Route path="/discover" element={<Discover/>}/>
              <Route path='/contact' element={<Contact/>}/>
              <Route path='/privacypolicy' element={<Privacypolicy/>}/>
            </Route>
     
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
