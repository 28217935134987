import React, { useEffect, useRef, useState } from 'react';
import "./Discover.css"
import C_home from "../../Images/Video/C_home.mp4"
import { Link } from 'react-router-dom';
import Utopia_Logo from "../../Images/Utopia_Logo.png";
import car from "../../Images/car.jpg";
import signaturewhite from "../../Images/signature-white.png";
import Intro from './Intro';
import EventsTickets from './EventsTickets';
import HotCommerce from './HotCommerce';
import Advertising from './Advertising';
import Connected from './Connected';
import GlobalPayment from './GlobalPayment';
const Discover = () => {

    const [paginationblock, setPaginationBlock] = useState(1);
    const [activeTab, setActiveTab] = useState(0);
    const tabs = ['Intro', 'Event Tickets', 'HOT Commerce', 'Advertising', 'ConnectD', 'Global Payment'];

    const debounceTimeout = useRef(null);
    const startYRef = useRef(0);
    const handleWheel = (event) => {
        if (debounceTimeout.current) return; // Skip if debounce is active

        if (event.deltaY < 0) {
            // Scrolling up
            setActiveTab((prev) => (prev > 0 ? prev - 1 : prev));
        } else if (event.deltaY > 0) {
            // Scrolling down
            setActiveTab((prev) => (prev < tabs.length - 1 ? prev + 1 : prev));
        }

        debounceTimeout.current = setTimeout(() => {
            debounceTimeout.current = null;
        }, 500); // Adjust the delay as needed
    };

    const handleTouchStart = (event) => {
        const touch = event.changedTouches[0];
        // touch.startY = touch.clientY;
        startYRef.current = touch.clientY;
    };

    const handleTouchMove = (event) => {

        // const touch = event.changedTouches[0];
        // const deltaY = touch.clientY - startYRef.current;

        // if (deltaY > 50) {

        //   setActiveTab((prev) => (prev > 0 ? prev - 1 : prev));
        // } else if (deltaY < -50) {

        //   setActiveTab((prev) => (prev < tabs.length - 1 ? prev + 1 : prev));
        // }

        // debounceTimeout.current = setTimeout(() => {
        //     debounceTimeout.current = null;
        // }, 500);

        if (debounceTimeout.current) {
            return; // Ignore if debounce is active
        }

        const touch = event.changedTouches[0];
        const deltaY = touch.clientY - startYRef.current;

        if (deltaY > 50) {
            // Swipe down
            setActiveTab((prev) => (prev > 0 ? prev - 1 : prev));
            debounceTimeout.current = setTimeout(() => {
                debounceTimeout.current = null;
            }, 500); // Debounce time
        } else if (deltaY < -50) {
            // Swipe up
            setActiveTab((prev) => (prev < tabs.length - 1 ? prev + 1 : prev));
            debounceTimeout.current = setTimeout(() => {
                debounceTimeout.current = null;
            }, 500); // Debounce time
        }
    };



    useEffect(() => {
        window.addEventListener('wheel', handleWheel);
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove);

        return () => {
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);

    useEffect(() => {
        setPaginationBlock(activeTab + 1);
    }, [activeTab]);

    const handletabblock = (tabNumber) => {
        setPaginationBlock(tabNumber);
        setActiveTab(tabNumber - 1);
    };

    return (

        <>
            {/* <ul className="blocks-pagination">
                <li
                    className={paginationblock === 1 ? "blocks-pagination__item current" : "blocks-pagination__item"}
                    data-index={1}
                    data-label="Intro"
                    onClick={() => handletabblock(1)}
                />
                <li className={paginationblock === 2 ? "blocks-pagination__item current" : "blocks-pagination__item"}
                    data-index={2}
                    data-label="Events Tickets"
                    onClick={() => handletabblock(2)} />
                <li
                    className={paginationblock === 3 ? "blocks-pagination__item current" : "blocks-pagination__item"}
                    data-index={3}
                    data-label="HOT Commerce"
                    onClick={() => handletabblock(3)}
                />
                <li
                    className={paginationblock === 4 ? "blocks-pagination__item current" : "blocks-pagination__item"}
                    data-index={4}
                    data-label="Advertising"
                    onClick={() => handletabblock(4)}
                />
                <li
                    className={paginationblock === 5 ? "blocks-pagination__item current" : "blocks-pagination__item"}
                    data-index={5}
                    data-label="ConnectD"
                    onClick={() => handletabblock(5)} />

                <li
                    className={paginationblock === 6 ? "blocks-pagination__item current" : "blocks-pagination__item"}
                    data-index={6}
                    data-label=" Global Payment "
                    onClick={() => handletabblock(6)} />
            </ul>

            {paginationblock === 1 && (
                
                < div className={`tab ${activeTab === 0 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                    <Intro />
                </div>
            )}

            {paginationblock === 2 && (

                < div className={`tab ${activeTab === 1 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                    <EventsTickets />
                </div>
            )}


            {paginationblock === 3 && (

                < div className={`tab ${activeTab === 2 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                    <HotCommerce />
                </div>
            )}


            {paginationblock === 4 && (

                < div className={`tab ${activeTab === 3 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                    <Advertising />
                </div>
            )}


            {paginationblock === 5 && (

                < div className={`tab ${activeTab === 4 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                    <Connected />
                </div>
            )}


            {paginationblock === 6 && (

                < div className={`tab ${activeTab === 5 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                    <GlobalPayment />
                </div>
            )} */}


            <>
                <ul className="blocks-pagination">
                    {tabs.map((tab, index) => (
                        <li
                            key={index}
                            className={paginationblock === index + 1 ? "blocks-pagination__item current" : "blocks-pagination__item"}
                            data-index={index + 1}
                            data-label={tab}
                            onClick={() => handletabblock(index + 1)}
                        />
                    ))}
                </ul>

                {paginationblock === 1 && (
                    <div className={`tab ${activeTab === 0 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                        <Intro />
                    </div>
                )}
                {paginationblock === 2 && (
                    <div className={`tab ${activeTab === 1 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                        <EventsTickets />
                    </div>
                )}
                {paginationblock === 3 && (
                    <div className={`tab ${activeTab === 2 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                        <HotCommerce />
                    </div>
                )}
                {paginationblock === 4 && (
                    <div className={`tab ${activeTab === 3 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                        <Advertising />
                    </div>
                )}
                {paginationblock === 5 && (
                    <div className={`tab ${activeTab === 4 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                        <Connected />
                    </div>
                )}
                {paginationblock === 6 && (
                    <div className={`tab ${activeTab === 5 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                        <GlobalPayment />
                    </div>
                )}
            </>





        </>
    )
}

export default Discover
