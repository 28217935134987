import React, { useEffect } from 'react';
import "./Contact.css"
import AOS from 'aos';
import "aos/dist/aos.css";
import C_home from "../../Images/Video/C_home.mp4"
const Contact = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            delay: 900,
        });
    })
    return (
        <>
            <section className='hero_content cuurentchm'>
                <video className="block__background block__background--video" autoPlay loop muted>
                    <source src={C_home} type="video/mp4" />
                </video>

                <div className='bg-pattern_cuurent'></div>

                <div className="block__content">

                    {/* <Link className="header__logo  lock__logo hp-logo" to="/" data-aos="zoom-in" data-aos-duration="1000">
                        <img src={headerlogo} alt='...' />
                    </Link>
                   
                    <h2 className="block__title-h2" data-aos="zoom-in" data-aos-duration="1000">
                        <p style={{ textAlign: 'center' }} className='diacpressrealsttxpp'>
                            <Link to="/discover" className='diacpressrealsttxpp'>
                                DISCOVER MORE</Link>
                            &nbsp; | &nbsp;
                            <Link to="" className='diacpressrealsttxpp'>
                                PRESS RELEASE
                            </Link>
                        </p>
                    </h2> */}


                    <div className='contactdivmain'>
                        <div className='contactdivmainflex'>
                            <div className='contactdivmainleft'>
                                <div className='contactinfodiv'>
                                    <h4 className='conatctstxtspppp'>Contact Info</h4>

                                    <div className='contactinfodivdivflx'>
                                        <div className="contactinfodivdileft">
                                            <div className='contactinfodivdileftmain'>
                                                <i className="fa-solid fa-envelope"></i>
                                            </div>
                                        </div>
                                        <div className="contactinfodivdiright">
                                            <p className='contattxtrtsttpp'>info@gmail.com</p>
                                        </div>
                                    </div>

                                    <div className='contactinfodivdivflx'>
                                        <div className="contactinfodivdileft">
                                            <div className='contactinfodivdileftmain'>
                                            <i class="fa-solid fa-phone"></i>
                                            </div>
                                        </div>
                                        <div className="contactinfodivdiright">
                                            <p className='contattxtrtsttpp'>+1239289929</p>
                                        </div>
                                    </div>

                                    <div className='contactinfodivdivflx'>
                                        <div className="contactinfodivdileft">
                                            <div className='contactinfodivdileftmain'>
                                            <i class="fa-solid fa-location-dot"></i>
                                            </div>
                                        </div>
                                        <div className="contactinfodivdiright">
                                            <p className='contattxtrtsttpp'>123 Anywhere st... any city</p>
                                        </div>
                                    </div>
                                    <div className='contxtggogleomaoobg'>
                                        <div className='contxtggogleomaoo'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14736.259831591067!2d88.4500778!3d22.576673699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1724677381930!5m2!1sen!2sin" width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='contactdivmainright'>
                                <div class="sliderbannerbgdivrightbg">
                                    <p class="lerantsxtpp">Contact Us</p>
                                </div>
                                <div className='contttcfrmminamettxtinptty'>
                                    <p  className='contttcfrmminamettxt'>Name : </p>
                                    <input type='text' />
                                </div>
                                <div className='contttcfrmminamettxtinptty'>
                                    <p  className='contttcfrmminamettxt'>Email : </p>
                                    <input type='text' />
                                </div>
                                <div className='contttcfrmminamettxtinptty'>
                                    <p  className='contttcfrmminamettxt'>Ph No. : </p>
                                    <input type='number' />
                                </div>
                                <div className='contttcfrmminamettxtinptty'>
                                    <p  className='contttcfrmminamettxt'>Address : </p>
                                    <input type='text' />
                                </div>
                                <div className='contttcfrmminamettxtinptty'>
                                    <p  className='contttcfrmminamettxt'>Subject : </p>
                                    <textarea cols={2} rows={2}/>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>






            </section>
        </>
    )
}

export default Contact
